<template>
    <card>
    <template v-slot:searchHeaderTitle>
      <h4 class="card-title">{{ $t('teaGarden.indent_submission') }}</h4>
    </template>
    <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <!-- fiscal year -->
                <b-col sm="4">
                  <ValidationProvider name="Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="fiscal_year_id"
                        v-model="data.fiscal_year_id"
                        :options="fiscalYearList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- garden -->
                <b-col sm="4">
                  <ValidationProvider name="Garden Name" vid="garden_id" :rules="{required: true}">
                    <b-form-group
                      label-for="garden_id"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{$t('teaGardenConfig.garden_name')}} <span class="text-danger">*</span>
                    </template>
                    <v-select
                      :disabled="isGardenAdmin"
                      id="garden_id"
                      v-model="data.garden_id"
                      :reduce="op => op.value"
                      :options="gardenList"
                      label="text"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.select')"
                      >
                      </v-select>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- company name -->
                <b-col sm="4">
                    <ValidationProvider name="Company Name" vid="company_name" :rules="{required: false}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="last_year_tea_production">
                            <template v-slot:label>
                                {{ $t('teaGardenConfig.company_name') }}
                            </template>
                            <b-form-input disabled
                                :value="currentLocale === 'en' ? garden_info.company_name_en : garden_info.company_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- company address -->
                <b-col sm="4">
                    <ValidationProvider name="Company Address" vid="company_name" :rules="{required: false}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="last_year_tea_production">
                            <template v-slot:label>
                                {{ $t('teaGardenConfig.company_address') }}
                            </template>
                            <b-form-input disabled
                                :value="currentLocale === 'en' ? garden_info.company_address_en : garden_info.company_address_bn"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- Total Land -->
                <b-col sm="4">
                    <ValidationProvider name="Total Land" vid="total_land" :rules="{required: false}">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="total_land">
                            <template v-slot:label>
                                {{ $t('teaGardenConfig.total_land') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                type="number"
                                @keypress="isNumber"
                                min="0"
                                v-model="garden_info.total_land"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- Total Cultivate Land -->
                <b-col sm="4">
                    <ValidationProvider name="Total Cultivable Land (Acre)" vid="total_cultivate_land" :rules="{required: true, max_value: garden_info.total_land }">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="total_cultivate_land">
                            <template v-slot:label>
                                {{ $t('teaGarden.total_cultivate_land') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                type="number"
                                @keypress="isNumber"
                                min="0"
                                v-model="data.total_cultivate_land"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <!-- last year tea production -->
                <b-col sm="4">
                    <ValidationProvider name="Last Year Tea Production (Kg.)" vid="last_year_tea_production" rules="required">
                        <b-form-group
                            slot-scope="{ valid, errors }"
                            label-for="last_year_tea_production">
                            <template v-slot:label>
                                {{ $t('teaGarden.last_year_tea_production') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                type="number"
                                @keypress="isNumber"
                                min="0"
                                v-model="data.last_year_tea_production"
                                :state="errors[0] ? false : (valid ? true : null)">
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
              </b-row>
              <!-- fertilizer request -->
              <b-row>
                <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_request') + ' ' + $t('globalTrans.m_ton')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                              <b-col sm="12">
                                <table class="table table-sm table-bordered section-tree-view-table">
                                  <thead>
                                    <tr>
                                      <th class="text-center" v-for="(item ,index) in data.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td v-for="(item ,index) in data.proposals" :key="index">
                                        <ValidationProvider :name="`${getFertilizerName(item.fertilizer_id)} Qty (M. Ton)`" :vid="`reqst_qty-${index}`" :rules="{ required: true, min_value :0 }">
                                          <b-form-group :label-for="`reqst_qty-${index}`" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                :id="`reqst_qty-${index}`"
                                                type="number"
                                                @keypress="isNumber"
                                                min="0"
                                                v-model="item.reqst_qty"
                                                :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <div class="invalid-feedback d-block" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                          </b-form-group>
                                        </ValidationProvider>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-col>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              </b-row>
              <!-- fertilizer collection place proposed -->
              <b-row>
                <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_collection_place_proposed')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- bcic sale center -->
                                <b-col sm="6">
                                <h6 class="mb-2 font-weight-bold">{{$t('teaGarden.bcic_sale_center')}}</h6>
                                <!-- center name -->
                                <b-col sm="12">
                                  <ValidationProvider name="Distribution Center" vid="proposed_bcic_sale_center_id" :rules="{required: true}">
                                    <b-form-group
                                      label-for="proposed_bcic_sale_center_id"
                                      slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{$t('teaGardenConfig.distribution_center_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <v-select
                                      id="proposed_bcic_sale_center_id"
                                      v-model="data.proposed_bcic_sale_center_id"
                                      :reduce="op => op.value"
                                      :options="bcicDistributionCenterList"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :placeholder="$t('globalTrans.select')"
                                      >
                                      </v-select>
                                    <div class="invalid-feedback d-block">
                                      {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <!-- center address -->
                                <b-col sm="12">
                                 <ValidationProvider name="Address" vid="address" :rules="{required: false}">
                                    <b-form-group
                                        slot-scope="{ valid, errors }"
                                        label-for="address">
                                        <template v-slot:label>
                                            {{ $t('teaGardenConfig.distribution_center_address') }}
                                        </template>
                                        <b-form-input disabled
                                            :value="getCenterAddress(data.proposed_bcic_sale_center_id)"
                                            :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                                </b-col>
                                 </b-col>
                                <!-- badc sale center -->
                                <b-col sm="6">
                                  <h6 class="mb-2 font-weight-bold">{{$t('teaGarden.badc_sale_center')}}</h6>
                                  <!-- center name -->
                                  <b-col sm="12">
                                    <ValidationProvider name="Distribution Center" vid="proposed_badc_sale_center_id" :rules="{required: true}">
                                      <b-form-group
                                        label-for="proposed_badc_sale_center_id"
                                        slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                        {{$t('teaGardenConfig.distribution_center_name')}} <span class="text-danger">*</span>
                                      </template>
                                      <v-select
                                        id="proposed_badc_sale_center_id"
                                        v-model="data.proposed_badc_sale_center_id"
                                        :reduce="op => op.value"
                                        :options="badcDistributionCenterList"
                                        label="text"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :placeholder="$t('globalTrans.select')"
                                        >
                                        </v-select>
                                      <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                      </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- center address -->
                                  <b-col sm="12">
                                  <ValidationProvider name="Address" vid="address" :rules="{required: false}">
                                      <b-form-group
                                          slot-scope="{ valid, errors }"
                                          label-for="address">
                                          <template v-slot:label>
                                              {{ $t('teaGardenConfig.distribution_center_address') }}
                                          </template>
                                          <b-form-input disabled
                                              :value="getCenterAddress(data.proposed_badc_sale_center_id)"
                                              :state="errors[0] ? false : (valid ? true : null)">
                                          </b-form-input>
                                          <div class="invalid-feedback">
                                              {{ errors[0] }}
                                          </div>
                                      </b-form-group>
                                  </ValidationProvider>
                                  </b-col>
                                </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              </b-row>
              <!-- last year history-->
              <b-row>
                <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('teaGarden.fertilizer_last_year_history')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                              <!-- received histroy -->
                              <b-col sm="12">
                                <h6 class="mb-2">{{$t('teaGarden.fertilizer_received_history')}} {{$t('globalTrans.m_ton')}}</h6>
                                <table class="table table-sm table-bordered section-tree-view-table">
                                  <thead>
                                    <tr>
                                      <slot v-if="previousData.proposals && Object.keys(previousData.proposals).length > 1">
                                      <th class="text-center" v-for="(item ,index) in previousData.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                      </slot>
                                      <slot v-else>
                                      <th class="text-center" v-for="(item ,index) in data.proposals" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                      </slot>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <slot v-if="previousData.proposals && Object.keys(previousData.proposals).length > 1">
                                        <td v-for="(item ,index) in previousData.proposals" :key="index">
                                          <b-overlay :show="previousDataLoding">
                                              <ValidationProvider name="Receipt Qty" :vid="`receipt_qty-${index}`" :rules="{ required: false, min_value :0 }">
                                              <b-form-group :label-for="`receipt_qty-${index}`" slot-scope="{ valid, errors }">
                                                <b-form-input
                                                    disabled
                                                    :id="`receipt_qty-${index}`"
                                                    type="number"
                                                    @keypress="isNumber"
                                                    min="0"
                                                    v-model="item.receipt_qty"
                                                    :state="errors[0] ? false : (valid ? true : null)">
                                                </b-form-input>
                                                <div class="invalid-feedback d-block" role="alert">
                                                  {{ errors[0] }}
                                                </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                          </b-overlay>
                                        </td>
                                      </slot>
                                      <slot v-else>
                                        <td v-for="(item ,index) in data.proposals" :key="index">
                                              <ValidationProvider name="Receipt Qty" :vid="`receipt_qty-${index}`" :rules="{ required: false, min_value :0 }">
                                              <b-form-group :label-for="`receipt_qty-${index}`" slot-scope="{ valid, errors }">
                                                <b-form-input
                                                    disabled
                                                    :id="`receipt_qty-${index}`"
                                                    type="number"
                                                    @keypress="isNumber"
                                                    min="0"
                                                    v-model="item.receipt_qty"
                                                    :state="errors[0] ? false : (valid ? true : null)">
                                                </b-form-input>
                                                <div class="invalid-feedback d-block" role="alert">
                                                  {{ errors[0] }}
                                                </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                        </td>
                                      </slot>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-col>
                              <!-- used history -->
                              <b-col sm="12">
                                <h6 class="mb-2">{{$t('teaGarden.fertilizer_used_history')}} {{$t('globalTrans.m_ton')}}</h6>
                                <table class="table table-sm table-bordered section-tree-view-table">
                                  <thead>
                                    <tr>
                                      <th class="text-center" v-for="(item ,index) in data.fertilizer_used_history" :key="index">{{getFertilizerName(item.fertilizer_id)}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td v-for="(item ,index) in data.fertilizer_used_history" :key="index">
                                        <ValidationProvider :name="`${getFertilizerName(item.fertilizer_id)} Qty (M. Ton)`" :vid="`used_qty-${index}`" :rules="{ required: true, min_value :0 }">
                                            <b-form-group :label-for="`used_qty-${index}`" slot-scope="{ valid, errors }">
                                              <b-form-input
                                                  :id="`used_qty-${index}`"
                                                  type="number"
                                                  @keypress="isNumber"
                                                  min="0"
                                                  v-model="item.used_qty"
                                                  :state="errors[0] ? false : (valid ? true : null)">
                                              </b-form-input>
                                              <div class="invalid-feedback d-block" role="alert">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-col>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
              </b-row>
              <b-row class="text-right">
                <b-col>
                  <b-button type="button" @click="saveUpdate(1)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                  <b-button type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                  <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
    </template>
  </card>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { fertilizerRequestStore, fertilizerRequestUpdate, lastYearFertilizerReqesHistory } from '../../../api/routes'
import { helpers } from '@/utils/helper-functions'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  data () {
    return {
      ItemShow: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      isGardenAdmin: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        garden_id: '',
        circular_id: 0,
        last_year_tea_production: '',
        total_cultivate_land: '',
        proposals: [{}],
        fertilizer_used_history: [{}]
      },
      previousData: {
        proposals: [{}],
        fertilizer_used_history: [{}]
      },
      previousDataLoding: false,
      garden_info: {},
      fertilizerList: []
    }
  },
  created () {
    if (this.$route.params.id) {
      const tmp = this.getData()
      this.data = tmp
    } else {
      this.getFertilizerList()
      this.getFertilizerUsedList()
    }
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      this.data.garden_id = this.isGardenAdminCheckGardenId()
    }
    this.data.circular_id = parseInt(this.$route.query.circular_id)
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    bcicDistributionCenterList () {
      return this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.filter(item => item.status === 1 && item.sales_center === 2)
    },
    badcDistributionCenterList () {
      return this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.filter(item => item.status === 1 && item.sales_center === 1)
    }
  },
  methods: {
    async saveUpdate (status = 0) {
      var check = await this.$refs.form.validate()
      if (check) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.data.status = status
        if (this.$route.params.id) {
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${fertilizerRequestUpdate}/${this.$route.params.id}`, this.data)
        } else {
          result = await RestApi.postData(teaGardenServiceBaseUrl, fertilizerRequestStore, this.data)
        }

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          if (this.isGardenAdminCheckGardenId()) {
            return this.$router.push({ name: 'tea_garden_service.garden.indent_submissions' })
          } else {
            return this.$router.push({ name: 'tea_garden_service.btb.indent_submission_list', query: { circular_id: this.$route.query.circular_id } })
          }
        } else {
          if (result.exist) {
            this.$toast.error({
              title: this.$t('globalTrans.error'),
              message: this.$t('teaGarden.exist_msg'),
              color: 'red'
            })
          }
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.params.id))
      return JSON.parse(JSON.stringify(tmpData))
    },
    back () {
      return this.$router.go(-1)
    },
    getGardenInfo (id) {
      this.garden_info = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
    },
    getFertilizerList () {
      this.data.proposals = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.filter(item => item.status === 1).map(itemData => {
        const obj = {
          fertilizer_id: itemData.value,
          sale_center_id: null,
          reqst_qty: null,
          receipt_qty: 0
        }
        return Object.assign({}, obj)
      })
    },
    getFertilizerUsedList () {
      this.data.fertilizer_used_history = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.filter(item => item.status === 1).map(itemData => {
        const obj = {
          fertilizer_id: itemData.value,
          used_qty: null
        }
        return Object.assign({}, obj)
      })
    },
    getFertilizerName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getCenterAddress (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerDistributionList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.distrib_center_address_en : obj.distrib_center_address_bn
      }
    },
    setSellSenterBCIC (id) {
       this.data.proposals = this.data.proposals.map(item => {
        const fertilizerName = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(findItem => findItem.value === item.fertilizer_id).text_en
        if (fertilizerName && fertilizerName.toLowerCase() === 'urea') {
          const setSellCenterId = {
            sale_center_id: id
          }
          return Object.assign({}, item, setSellCenterId)
        }
        return Object.assign({}, item)
       })
    },
    setSellSenterBADC (id) {
       this.data.proposals = this.data.proposals.map(item => {
        const fertilizerName = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(findItem => findItem.value === item.fertilizer_id).text_en
        if (fertilizerName && fertilizerName.toLowerCase() !== 'urea') {
          const setSellCenterId = {
            sale_center_id: id
          }
          return Object.assign({}, item, setSellCenterId)
        }
        return Object.assign({}, item)
       })
    },
    async getPreviousRequestHistory (gardenId) {
      const paramsData = {
          garden_id: gardenId,
          id: this.$route.params.id,
          fiscal_year_id: this.data.fiscal_year_id
      }
      this.previousDataLoding = true
      const result = await RestApi.getData(teaGardenServiceBaseUrl, lastYearFertilizerReqesHistory, paramsData)
        if (result.success) {
            const data = result.data
            if (data) {
                this.previousData = data
            }
        } else {
          this.previousData = {
            proposals: [],
            fertilizer_used_history: []
          }
        }
        this.previousDataLoding = false
    }
  },
  watch: {
    'data.garden_id': function (newVal, oldVal) {
      if (newVal) {
        this.getGardenInfo(newVal)
        this.getPreviousRequestHistory(newVal)
      } else {
        this.garden_info = {}
      }
    },
    'data.fiscal_year_id': function (newVal, oldVal) {
      if (newVal && this.data.garden_id) {
        this.getPreviousRequestHistory(this.data.garden_id)
      }
    },
    'data.proposed_bcic_sale_center_id': function (newVal, oldVal) {
      if (newVal) {
        this.setSellSenterBCIC(newVal)
      }
    },
    'data.proposed_badc_sale_center_id': function (newVal, oldVal) {
      if (newVal) {
        this.setSellSenterBADC(newVal)
      }
    }
  }
}
</script>
